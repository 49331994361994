import { deleteInitFetch, getInitFetch, putInitFetch, postAuthInitFetch, patchInitFetch, InitError, postInitFetch } from "./baoConnexion";

/**---------------
 *      INIT
 * ---------------
 */
let apiUrl:string;
  if (import.meta.env.MODE === 'blg') {
    apiUrl = import.meta.env.VITE_API_URL_BLG;
  } else if (import.meta.env.MODE === 'prodBlg') {
    apiUrl = import.meta.env.VITE_API_URL_BLGPROD;
  } else if (import.meta.env.MODE === 'gb') {
    apiUrl = import.meta.env.VITE_API_URL_GB;
  } else if (import.meta.env.MODE === 'production') {
    apiUrl = import.meta.env.VITE_API_URL_PROD;
  } else if (import.meta.env.MODE === 'preproduction') {
    apiUrl = import.meta.env.VITE_API_URL_PREPROD;
  } else if (import.meta.env.MODE === 'pld') {
    apiUrl = import.meta.env.VITE_API_URL_pld;
  } else if (import.meta.env.MODE === 'gm') {
    apiUrl = import.meta.env.VITE_API_URL_GM;
  } else if (import.meta.env.MODE === 'er') {
    apiUrl = import.meta.env.VITE_API_URL_ER;
  } else {
    console.error('Mode non géré:', import.meta.env.MODE);
  }
  
/**---------------
 *      FUNC
 * ---------------
 */
export function getFetch(url:string) {
  return new Promise((resolve, reject) => {
    const init = getInitFetch() as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }

    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        response.json().then((body) => {
          console.log(body.message);
        });
        throw new Error(`Erreur de requête : ${response}`);
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}

export function patchFetch(url:string, body:unknown) {
  return new Promise((resolve, reject) => {
    const init = patchInitFetch(body) as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }
    
    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        throw new Error('Erreur de requête');
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}

export function deleteFetch(url:string, body:unknown) {
  return new Promise((resolve, reject) => {
    const init = deleteInitFetch(body) as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }
    
    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        throw new Error('Erreur de requête');
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}

export function putFetch(url:string, body:unknown) {
  return new Promise((resolve, reject) => {
    const init = putInitFetch(body) as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }
    
    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        throw new Error('Erreur de requête');
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}

export function postFetch(url:string, body:unknown) {
  return new Promise((resolve, reject) => {
    const init = postInitFetch(body) as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }
    
    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        throw new Error('Erreur de requête');
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}

export function postFetchAuth(url:string, body:unknown) {
  return new Promise((resolve, reject) => {
    const init = postAuthInitFetch(body) as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }
    
    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        throw new Error('Erreur de requête');
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}

export function getFetchAuth(url:string) {
  return new Promise((resolve, reject) => {
    const init = getInitFetch() as RequestInit | InitError;

    if ('message' in init) {
      // Si init est une instance de InitError, il y a eu une erreur dans getInitFetch()
      reject(new Error(init.message));
      return;
    }
    
    fetch(`${apiUrl}${url}`, init).then((response) => {
      if (response.ok) {
        return response.json(); // Cela extrait le corps de la réponse au format JSON
      } else {
        throw new Error('Erreur de requête');
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error)
    });
  })
}